/* source: https://codepen.io/johholl/pen/DGfem */
.symbol {
  display: block;
  width: 16px;
  height: 16px;
  transform: rotate(45deg) translateX(4px) translateY(-4px);
}
.circle {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-color: rgba(0, 0, 0, 0.86);
  border-style: solid;
  border-width: 1em 1em 0 0;
  border-radius: 0 100% 0 0;
  opacity: 1;
}
.play {
  opacity: 0;
  animation: pulse 1s infinite;
}
.first {
  width: 5em;
  height: 5em;
  animation-delay: 400ms;
}
.second {
  width: 3em;
  height: 3em;
}
.third {
  width: 1em;
  height: 1em;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.86);
  animation: none;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
}
